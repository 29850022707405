import { FormArray, FormControl, FormGroup } from '@angular/forms'

export function findInvalidControls(control: FormGroup | FormArray, prefix = '') {
  let invalid: string[] = []
  const controls = control.controls
  for (const name in controls) {
    let control = controls[name]
    if (control.invalid) {
      let invalidName = prefix ? prefix + '.' + name : name

      if (control['controls']) {
        invalid = invalid.concat(findInvalidControls(control, invalidName))
      } else {
        invalid.push(invalidName)
      }
    }
  }
  return invalid
}

export function focusInvalidControl(control: FormGroup | FormArray, prefix = '') {
  let invalids = findInvalidControls(control, prefix)
  let foundControl: FormControl
  if (invalids.length > 0) {
    for (let invalid of invalids) {
      foundControl = findControl(control, invalid)
      if (foundControl) {
        break
      }
    }

    if (foundControl && (foundControl as any).hostComponent) {
      ;(foundControl as any).hostComponent.focus()
    }
  }
}

export function findControl(control: FormGroup | FormArray, controlName: string, step: number = 1) {
  let foundControl: any
  const controls = control.controls
  let controlNames = controlName.split('.', step)
  if (controlNames.length >= step) {
    for (const name in controls) {
      let control = controls[name]

      if (name == controlNames[step - 1]) {
        foundControl = control
        if (foundControl['controls']) {
          foundControl = findControl(foundControl, controlName, step + 1)
        }
        return <FormControl>foundControl
      }
    }
  }
  return <FormControl>foundControl
}
