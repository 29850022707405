import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BaseComponent,
  checkPermissionsFromStorage,
  CredentialsService,
  I18nService,
  Logger
} from '@app/core';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  SCCoreService,
  SCDocumentsManager,
  SCDocumentsManagerAttachmentCategory,
  SCDocumentsManagerCategory,
  SCDocumentsManagerOriginTemplate,
  SCDocumentsManagerService,
  SCDocumentsManagerVersion,
  SCImageGalleryService,
  SCImageTag,
  SCShellHeaderConfig,
  SCShellHeaderService,
  SCTableAction,
  SCTableService,
  SCTableUrlData
} from '@symblcrowd/ng-symblcrowd';
import { isArray } from 'lodash';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TranslationsService } from './core/translations.service';

const log = new Logger('App')

const appHeight = () => {
  const doc = document.documentElement
  if (doc) {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  } else {
    doc.style.setProperty('--app-height', `100vh`)
  }
}
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public isInit = false
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translationService: TranslationsService,
    private i18nService: I18nService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private headerService: SCShellHeaderService,
    private scCoreService: SCCoreService,
    private scTableService: SCTableService,
    private scDocumentsManagerService: SCDocumentsManagerService,
    private galleryService: SCImageGalleryService,
    private credentialsService: CredentialsService,
    private translateService: TranslateService
  ) {
    super()
    this.headerService.setCheckConfig(this.checkHeaderConfig)
    this.scTableService.setCheckActions(this.checkTableActions)
    this.loadIcons()
    window.addEventListener('resize', appHeight)
  }

  ngOnInit() {
    console.log(this.credentialsService.credentials)
    appHeight()
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode()
    }

    log.debug('init')

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages)
    this.translateService.use(environment.defaultLanguage || navigator.language)

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd))

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title))
        }
      })

    this.scCoreService.baseUrl = environment.serverUrl + environment.apiVersion
    this.scCoreService.defaultId = ''

    this.scCoreService.setMapData(data => {
      return { data: data.data, totalCount: data.paging ? data.paging.total_count : data.data.length }
    })

    this.scTableService.showSearchFields = true

    this.scTableService.setMapData(data => {
      return { data: data.data, totalCount: data.paging ? data.paging.total_count : data.data.length }
    })

    this.scTableService.setMapUrlData(resp => {
      let data = resp.data

      let newData: SCTableUrlData = {
        tableActions: data.tableActions,
        tableHeaders: data.tableHeaders,
        tableHeadersDisplayed: data.tableHeadersDisplay
      }

      if (newData.tableActions && newData.tableActions.length > 0) {
        newData.tableHeadersDisplayed.push('actions')
      }
      return newData
    })

    this.scDocumentsManagerService.setMapCategoryData(resp => {
      let newData: SCDocumentsManagerCategory = {
        index: resp.id,
        categoryName: resp.category_name,
        documents: resp.documents,
        totalCount: resp.total_count
      }

      return newData
    })

    this.scDocumentsManagerService.setMapOriginTemplateData(resp => {
      let newData: SCDocumentsManagerOriginTemplate = {
        index: resp.id,
        hasDocument: resp.has_document,
        originType: resp.origin_type,
        templateDescription: resp.template_description,
        templateName: resp.template_name,
        templateDocument: resp.template_document
          ? ({
              index: resp.template_document.id,
              version: resp.template_document.version,
              currentVersion: resp.template_document.current_version,
              fileType: resp.template_document.file_type
            } as SCDocumentsManager)
          : undefined
      }

      return newData
    })

    this.scDocumentsManagerService.setMapDocumentData(resp => {
      let newData: SCDocumentsManager = {
        index: resp.id,
        documentName: resp.document_name ? resp.document_name : resp.original_filename,
        documentDate: resp.created_at,
        fileType: resp.file_type,
        currentVersion: resp.current_version,
        currentVersionName: resp.current_version_name,
        version: resp.version,
        versionName: resp.version_name,
        description: resp.description,
        filename: resp.filename,
        versions: undefined,
        document_type: resp.document_type,
        attribute_groups: resp.attribute_groups,
        originTemplate: resp.origin_template
          ? this.scDocumentsManagerService.mapOriginTemplateData(resp.origin_template)
          : undefined
        // data?: T
        // search_words
      };

      return newData;
    });

    this.scDocumentsManagerService.setMapDocumentVersionData(resp => {
      let newData: SCDocumentsManagerVersion = {
        index: resp.id,
        originalFilename: resp.original_filename,
        file_date: resp.created_at,
        version: resp.version,
        fileType: resp.file_type
      }

      return newData
    })

    this.scDocumentsManagerService.setMapDocumentDataForRequest(resp => {
      let newData: any = {
        id: resp.index,
        document_name: resp.documentName,
        file_type: resp.fileType,
        current_version: resp.currentVersion,
        current_version_name: resp.currentVersionName,
        version: resp.version,
        version_name: resp.versionName,
        description: resp.description,
        filename: resp.filename,
        versions: resp.versions,
        document_type: resp.document_type
      };
      return newData
    })

    this.scDocumentsManagerService.setMapAttachmentCategoryData(resp => {
      let newData: SCDocumentsManagerAttachmentCategory = {
        index: resp.id,
        attachmentCategory: resp.category_name,
        documents: resp.documents ? resp.documents.map((document: any) => this.scDocumentsManagerService.mapDocumentData(document)) : undefined
      }

      return newData
    })

    this.galleryService.setMapImages((data: any) => {
      if (!isArray(data.data)) {
        data.data = [data.data]
      }

      return data.data.map((x: any) => ({
        ...x,
        createdAt: x.created_at,
        filename: x.original_filename,
        tags: x.search_words.map(
          (t: any): SCImageTag => ({
            id: t.id,
            name: t.search_word,
            hasImages: t.has_images
          })
        )
      }))
    })

    this.galleryService.setMapTagsData((data: any) => {
      return data.data.map((x: any) => ({
        ...x,
        name: x.search_word,
        hasImages: x.has_images
      }))
    })

    this.loadIcons()

    // let observablesForInit = [this.credentialsService.basicSettings$, this.translationService.getLanguageFiles()]
    // this.addSubscription(combineLatest(observablesForInit)).subscribe(resp => {
    //   this.i18nService.addMultipleTranslations(resp[1] as any)
    //   this.isInit = true
    // })
  }

  ngOnDestroy() {
    this.i18nService.destroy()
  }

  checkTableActions(actions: SCTableAction[]) {
    return actions
  }

  checkHeaderConfig(config: SCShellHeaderConfig) {
    for (let i = config.actions.length - 1; i >= 0; i--) {
      let action = config.actions[i]
      if ((<any>action).requiredPermissons && !checkPermissionsFromStorage((<any>action).requiredPermissons)) {
        config.actions.splice(i, 1)
      }
    }

    for (let i = config.splitActions.length - 1; i >= 0; i--) {
      let action = config.splitActions[i]
      if ((<any>action).requiredPermissons && !checkPermissionsFromStorage((<any>action).requiredPermissons)) {
        config.splitActions.splice(i, 1)
      }
    }
    return config
  }

  loadIcons() {
    //Menu Icons
    this.iconRegistry.addSvgIcon(
      'dashboard_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/dashboard-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'company',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/dashboard-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'chevron_right_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/chevron_right-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'carbon_application_20',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/custom/icons/carbon/20/application-20.svg')
    )

    this.iconRegistry.addSvgIcon(
      'carbon_collaborate_20',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/custom/icons/carbon/20/collaborate-20.svg')
    )

    this.iconRegistry.addSvgIcon(
      'add_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/add-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'save_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/save-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'edit_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/edit-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'description_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/description-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'create_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/create-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'delete_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/delete-24px.svg')
    )
  }
}
