import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({
  name: 'imgSrcAsBlob'
})
export class ImgSrcAsBlobPipe implements PipeTransform {
  constructor(private http: HttpClient, private ds: DomSanitizer) {}

  async transform(value: string, headers: {[idx: string]: string}) {
    const result = new Promise((resolve, reject) => {
       if (!value.startsWith("https://") && !value.startsWith("http://")) return resolve(null);
        this.http.get(value, {headers: headers, responseType: "blob"}).subscribe((resp : any) => {          
            const url = this.ds.bypassSecurityTrustUrl(URL.createObjectURL(resp));
            resolve(url);
        });
    });
    
    return result
  }
}
