import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '@app/core';
import { checkPermissions, CredentialsService, RequiredPermission } from '@app/core/authentication/credentials.service';
import { BaseService } from '@app/core/helperclasses/baseservice';
import { I18nService } from '@app/core/i18n.service';
import { AuthAccount, AuthAccountAdditionalData } from '@app/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  deepCopy,
  SCData,
  SCGlobalSearchResult,
  SCGlobalSearchResultCategory,
  SCGlobalSearchService,
  SCGlobalSearchUrlConfig,
  SCProfileService,
  SCShellHeaderService,
  SCShellService,
  SCShellSidenavMenu,
  SCSmartBreadcrumbsService
} from '@symblcrowd/ng-symblcrowd';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthenticationService } from './../core/authentication/authentication.service';
import { RoutingService } from './../core/routing.service';
import { environment } from '@env/environment';

export interface SidenavMenu extends SCShellSidenavMenu {
  requiredPermissions?: RequiredPermission[]; // ODER verknüpft
  menu?: SidenavMenu[];
}

@UntilDestroy()
@Injectable()
export class ShellService extends BaseService {
  public navigationSections: SidenavMenu[] = [
    {
      menu: [
        {
          title: 'companies',
          routerLink: '/companies',
          iconType: 'MAT',
          icon: 'business',
          isActive: false
        },
        {
          title: 'admin_users',
          routerLink: '/users/admins',
          iconType: 'MAT',
          icon: 'people',
          isActive: false,
          requiredPermissions: [{ module_key: 'SUPER_ADMIN', permission: 1 }]
        },
        {
          title: 'users',
          routerLink: '/users/users',
          iconType: 'MAT',
          icon: 'people',
          isActive: false,
          requiredPermissions: [{ module_key: 'SUPER_ADMIN', permission: 1 }]
        }
      ]
    }
  ];

  public parsedNavigationSections: SidenavMenu[];

  public globalSearchSub: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private scShellService: SCShellService,
    private scShellSCShellHeaderService: SCShellHeaderService,
    private scGlobalSearchService: SCGlobalSearchService,
    private http: HttpService,
    private routingService: RoutingService,
    private breadcrumbsService: SCSmartBreadcrumbsService,
    public scProfileService: SCProfileService
  ) {
    super();
    let darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    //this.getMenu(undefined) //Use this if you have no Backend yet
    this.credentialsService.$credentials
      .pipe(filter(credentials => !!credentials && !!credentials.id))
      .subscribe(resp => {
        let imgPlaceholder =
          resp.first_name && resp.last_name
            ? resp.first_name.charAt(0) + resp.last_name.charAt(0)
            : resp.username.charAt(0) + resp.username.charAt(1);
        this.scProfileService.profile = { imgPlaceholder: imgPlaceholder };

        let userIsTracking: AuthAccountAdditionalData;
        if (
          this.credentialsService.credentials.additional_data &&
          this.credentialsService.credentials.additional_data.length > 0
        ) {
          userIsTracking = this.credentialsService.credentials.additional_data.find(
            data => data.data_key == 'is_tracking'
          );
        }
        this.getMenu(resp);

        this.scShellService.setConfig({
          // todo: move logo files to server - insert URLs here - remove assets/brand
          logo: environment.serverUrl + environment.apiVersion + '/general/logo/light',
          logoDark: environment.serverUrl + environment.apiVersion + '/general/logo/dark',
          enableSearch: false,
          saveAsideToggleState: true,
          headerConfig: {
            headerTopConfig: {
              actions: [
                {
                  index: 'profile',

                  type: 'PROFILE',
                  profileActions: [{ index: 'logout', label: 'Abmelden', icon: 'login' }]
                }
              ],
              defaultActionsLimit: 4,
              customTopHeader: false
            }
          }
        });
      });

    this.scShellSCShellHeaderService.headerActionClicked.subscribe(resp => {
      switch (resp.action.type) {
        case 'PROFILE':
          switch (resp.button.index) {
            case 'logout':
              this.logout();
              break;

            case 'profile':
              this.routingService.openProfileLink(undefined, resp.event);
              break;
          }
          break;
        case 'QUICKACTIONS':
          switch (resp.button.index) {
            default:
              break;
          }
          break;
        case 'CUSTOM':
          break;
      }
    });

    this.setupGlobalSearch();
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  logout() {
    this.addSubscription(this.authenticationService.logout()).subscribe(() =>
      this.router.navigate(['/login'], { replaceUrl: true })
    );
  }

  getMenu(credentials: AuthAccount) {
    let menu = deepCopy(<SidenavMenu[]>this.navigationSections);

    this.parseMenu(menu, credentials);
    this.parsedNavigationSections = menu;
    this.scShellService.setNavigationSections(this.parsedNavigationSections);
    //this.scShellService.asideToggle = true
  }

  parseMenu(menu: SidenavMenu[], credentials: AuthAccount) {
    for (let i = menu.length - 1; i >= 0; i--) {
      let section = menu[i];
      if (section.requiredPermissions) {
        let hasOnePermission = false;
        for (let permission of section.requiredPermissions) {
          if (checkPermissions([permission], credentials)) {
            hasOnePermission = true;
          }
        }
        if (!hasOnePermission) {
          menu.splice(i, 1);
        }
      } else if (section.menu) {
        this.parseMenu(section.menu, credentials);
        if (section.menu.length == 0) {
          menu.splice(i, 1);
        }
      }
    }
  }

  setupGlobalSearch() {
    this.scGlobalSearchService.setMapSearchFieldData((data: any) => {
      return data.data.map((x: any) => ({
        label: x.search_in_name,
        index: x.search_in_key,
        isActive: true
      }));
    });

    let globalSearchUrlConfig: SCGlobalSearchUrlConfig = {
      searchFieldsUrl: '/search/possible-searches',
      storeSearchFields: true
    };
    // this.scGlobalSearchService.setGlobalSearchUrlConfig(globalSearchUrlConfig)

    // this.scGlobalSearchService.searchFields = [{ index: 'CONTACT', label: 'contacts', isActive: true }]
    this.scGlobalSearchService.globalSearchForm.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(change => {
        this.globalSearch(change);
      });

    this.addSubscription(this.scGlobalSearchService.searchFieldClicked)
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(resp => {
        this.globalSearch();
      });

    this.scGlobalSearchService.searchResultClicked.pipe(filter(resp => resp != undefined)).subscribe(resp => {
      let path = ``;

      this.breadcrumbsService.resetBreadcrumbs();
      this.scGlobalSearchService.searchResultCategories = [];

      // switch (resp.category.data.result_type) {
      //   case 'ITEM':
      //     this.routingService.openItemDetailsLink(resp.result.data.result_data, undefined, resp.event)
      //     break
      //   case 'FLEET_VEHICLE':
      //     this.routingService.openFleetVehicleDetailsLink(resp.result.data.result_data, undefined, resp.event)
      //     break
      //   case 'CONTACT':
      //   case 'CONTACTS_CONTACT_PERSON':
      //     this.routingService.openContactDetailsLink(resp.result.data.result_data, undefined, resp.event)
      //     break
      //   default:
      //     if (resp.category.data.result_type.includes('BILLING_')) {
      //       this.routingService.openBillingDetailsLink(resp.result.data.result_data, undefined, resp.event)
      //     } else {
      //       console.log('no routing for category found', resp.category.data.result_type)
      //     }
      //     break

      //   //erweitern
      //   //können wir hier eine Funktion aus den modulen/helpern zurückgeben, sodass wir hier kein switch case machen müssen?
      //   //absprache mit Michael und Jonathan - delegate Methoden?
      // }

      // if (resp.event.ctrlKey || resp.event.metaKey) {
      //   let url = this.router.createUrlTree([path])
      //   window.open(url.toString())
      // } else {
      //   this.router.navigate([path])
      // }
    });
  }

  public globalSearch(search?: string) {
    if (this.globalSearchSub) {
      this.globalSearchSub.unsubscribe();
    }
    if (search == undefined) {
      search = this.scGlobalSearchService.globalSearchForm.value;
    }
    let params = new HttpParams();
    params = params.append('search', search);

    for (let field of this.scGlobalSearchService.searchFields) {
      if (field.isActive) {
        params = params.append('search_in', field.index);
      }
    }

    this.globalSearchSub = this.http.get('/search/global', { params: params }).subscribe((resp: SCData<any[]>) => {
      let categories = resp.data.map(data => {
        let category: SCGlobalSearchResultCategory = {
          data: data,
          categoryName: data.result_type_name,
          results: data.search_results.map((dataResult: any) => {
            let result: SCGlobalSearchResult = {
              label: dataResult.result_text,
              data: dataResult
            };
            return result;
          })
        };
        return category;
      });
      this.scGlobalSearchService.searchResultCategories = categories;
    });
  }
}
