import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './../material.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

@NgModule({
    imports: [SharedModule, MatDialogModule, ReactiveFormsModule, MaterialModule, TranslateModule, CommonModule],
    declarations: [AlertDialogComponent],
    providers: []
})
export class DialogsModule { }
