import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import { SCModule, SCRouteReuser } from '@symblcrowd/ng-symblcrowd';
import { NgPipesModule } from 'angular-pipes';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogsModule } from './dialogs/dialogs.module';
import { MaterialModule } from './material.module';
import { LoginModule } from './pages/login/login.module';
import { ShellModule } from './shell/shell.module';

export let AppInjector: Injector;

registerLocaleData(localeDE);

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    DeviceDetectorModule.forRoot(),
    //SharedModule,
    MatIconModule,
    DialogsModule,
    SCModule,
    ShellModule,
    //Modules with routing
    LoginModule,
    //Global routing
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    NgPipesModule
  ],
  declarations: [AppComponent],
  providers: [
    CookieService,
    { provide: RouteReuseStrategy, useClass: SCRouteReuser },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE' // 'de-DE' for Germany, 'fr-FR' for France ...
    }
    /*{
      provide: HttpClient,
      useClass: HttpService
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
