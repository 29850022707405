import { Component, OnDestroy } from "@angular/core";
import { SCBaseService } from "@symblcrowd/ng-symblcrowd";

// TODO: Add Angular decorator.
@Component({
    template: ''
  })
export class BaseService extends SCBaseService implements OnDestroy {
    ngOnDestroy() {
        super.ngOnDestroy()
    }
}