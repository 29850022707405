import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'sanitize'})
export class SanitizeHtml implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(htmlContent: string) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
}