import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ID, SCSmartBreadcrumbsService } from '@symblcrowd/ng-symblcrowd';
import { filter } from 'rxjs/operators';
import { AlertDialogAction, AlertDialogComponent, AlertDialogConfig } from './../dialogs/alert-dialog/alert-dialog.component';
import { BaseService } from './helperclasses/baseservice';
import { Company } from '@app/models/companies';
import { AuthAccount } from '@app/models';
import { Account } from '@app/models/accounts';

export interface OpenUrlConfig {
  mouseEvent?: RoutingMouseEvent
  openInNewTab?: boolean
  resetBreadCrumbs?: boolean
  queryParams?: any
}

export type RoutingMouseEvent = MouseEvent | { openInNewTab?: boolean; ctrlKey?: boolean; metaKey?: boolean }
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RoutingService extends BaseService {
  private redirectUrl: string;
  private redirectUrlIsNew = false;
  constructor(
    private dialogService: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private smartBreadcrumbsService: SCSmartBreadcrumbsService
  ) {
    super();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (
        !router.getCurrentNavigation().extras.state ||
        !this.router.getCurrentNavigation().extras.state.keepRedirectUrl
      ) {
        this.resetRedirectUrl();
      }
      this.redirectUrlIsNew = false;
      //this.resetRedirectUrl()
    });
  }

  getCurrentRoute() {
    return this.router.routerState.snapshot.url;
  }

  setRedirectUrl(redirectUrl?: string) {
    if (!redirectUrl) {
      redirectUrl = this.getCurrentRoute();
    }
    this.redirectUrl = redirectUrl;
    this.redirectUrlIsNew = true;
  }

  renewRedirectUrl() {
    this.redirectUrlIsNew = true;
  }

  resetRedirectUrl() {
    this.redirectUrl = undefined;
    this.redirectUrlIsNew = false;
  }

  get clientId() {
    return this.route.snapshot.firstChild.params['clientId'];
  }

  public openUrl(
    url: any[],
    navigationExtras?: NavigationExtras,
    mouseEvent?: any,
    resetBreadCrumbs?: boolean,
    openInNewTab?: boolean
  ) {
    if (openInNewTab || (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey || mouseEvent.openInNewTab))) {
      let urlTree = this.router.createUrlTree(url, navigationExtras);
      if (mouseEvent?.shiftKey) {
        console.log('SHIFT KEY PRESSED');
        window.open(
          urlTree.toString(),
          '_blank',
          'location=yes,height=570,width=520,scrollbars=yes,status=yes,modal=yes'
        );
      } else {
        window.open(urlTree.toString());
      }
    } else {
      if (this.redirectUrl && !this.redirectUrlIsNew) {
        url = [this.redirectUrl];
        this.resetRedirectUrl();
      }
      if (resetBreadCrumbs) {
        this.smartBreadcrumbsService.resetBreadcrumbs();
      }
      this.router.navigate(url, {
        ...navigationExtras,
        state: { ...navigationExtras.state, keepRedirectUrl: this.redirectUrlIsNew }
      }); //navigationExtras) //
    }
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  public setUrl(
    url: any[],
    navigationExtras?: NavigationExtras,
    mouseEvent?: any,
    resetBreadCrumbs?: boolean,
    openInNewTab?: boolean
  ) {
    const routeUrl = this.router
      .createUrlTree(url, { relativeTo: this.route, queryParams: navigationExtras.queryParams })
      .toString();
    this.location.go(routeUrl);
  }

  public openAlertDialog(title: string, body: string, actions: AlertDialogAction[], config?: AlertDialogConfig) {
    return this.dialogService
      .open(AlertDialogComponent, {
        data: {
          title: title,
          body: body,
          actions: actions,
          config: config
        }
      })
      .afterClosed();
  }

  public openProfileLink(queryParams?: any, mouseEvent?: RoutingMouseEvent) {
    this.openUrl(['profile'], { queryParams: queryParams }, mouseEvent);
  }

  public openCompanyEditLink(company: Company | ID, queryParams: any, mouseEvent: RoutingMouseEvent) {
    const companyId = typeof company === 'string' ? company : company ? (company as Company).id : '';
    let urlTree = company ? ['companies', companyId, 'edit'] : ['companies', 'create'];
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent);
  }

  openCompanyDetailsLink(company: Company, queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['companies', company.id, 'details'], { queryParams: queryParams }, event);
  }

  openUsersOverviewLink(queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['users', 'users'], { queryParams: queryParams }, event);
  }

  openUserEditLink(user: Account, queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['users', 'users', user.id, 'edit'], { queryParams: queryParams }, event);
  }

  openAdminUserCreateLink(queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['users', 'admins', 'create'], { queryParams: queryParams }, event);
  }

  openAdminUsersOverviewLink(queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['users', 'admins'], { queryParams: queryParams }, event);
  }

  openAdminUserEditLink(user: AuthAccount, queryParams: any, event: RoutingMouseEvent) {
    this.openUrl(['users', 'admins', user.id, 'edit'], { queryParams: queryParams }, event);
  }
}
