import { Component, OnInit } from '@angular/core'
import { CredentialsService } from '@app/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SCShellService } from '@symblcrowd/ng-symblcrowd'
import { filter } from 'rxjs/operators'
import { ShellService } from './shell.service'

@UntilDestroy()
@Component({
  selector: 'app-shell',
  template: `
    <sc-shell></sc-shell>
  `
})
export class ShellComponent implements OnInit {
  constructor(public shellService: ShellService, public scShellService: SCShellService, private credentialsService: CredentialsService) {}

  ngOnInit() {
    if (window.opener && window.opener !== window && window.name != '') {
      this.scShellService.inPopupWindowOpened = true
    }

    //this.scShellService.isInit = true //Use this if you have no Backend yet
    this.credentialsService.$credentials.pipe(filter(credentials => !!credentials && !!credentials.id)).subscribe(credentials => {
      if (credentials.theme) {
        document.body.classList.add(credentials.theme)
      }
      //this.initThemeThemeType = true;
      //if (this.initTheme && this.initThemeThemeType) {
      this.scShellService.isInit = true
      //}
    })
  }
}
