import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpService } from '@app/core/http/http.service'
import { AuthAccount } from '@app/models'
/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
import { UntilDestroy } from '@ngneat/until-destroy'
import { SCData } from '@symblcrowd/ng-symblcrowd'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { CredentialsService } from './credentials.service'
import { environment } from '@env/environment'

export interface LoginContext {
  username: string
  password: string
  remember?: boolean
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpService,
    private router: Router
  ) {
    if (this.credentialsService.credentials) {
      this.getCurrentUser()
    }
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<SCData<AuthAccount>> {
    return this.loginHelper(context).pipe(
      map(res => {
        this.credentialsService.setCredentials(res.data, context.remember)
        return res
      })
    )
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials()
    this.credentialsService.settings = undefined
    return of(true)
  }

  loginHelper(context: LoginContext): Observable<SCData<AuthAccount>> {
    return <Observable<SCData<AuthAccount>>>this.http.post('/auth/login', context)
  }

  getCurrentUser() {
    return this.getCurrentUserHelper().subscribe(
      res => {
        res.data.session_token = this.credentialsService.token
        this.credentialsService.setCredentials(res.data, this.credentialsService.remember)
              },
      err => {
        if (!(this.credentialsService.credentials && this.credentialsService.credentials.id)) {
          this.credentialsService.setCredentials()
          this.router.navigate(['/login'], { queryParams: { redirect: this.router.url }, replaceUrl: true })
        }
      }
    )
  }

  getCurrentUserHelper(): Observable<any> {
    return <Observable<AuthAccount>>this.http.get('/admin/accounts/admins/me');
  }
}
