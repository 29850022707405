import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, CredentialsService } from '@app/core';
import { BaseComponent } from '@app/core/helperclasses/basecomponent';

const log = new Logger('Login');

import { UntilDestroy } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;

  public logoUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    this.logoUrl = environment.serverUrl + environment.apiVersion + '/general/logo';
    let darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (darkModeOn) {
      this.logoUrl = this.logoUrl + '/dark';
    } else {
      this.logoUrl = this.logoUrl + '/light';
    }

    /*
    if (this.credentialsService.credentials) {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
    }*/
  }

  login() {
    this.isLoading = true;

    this.addSubscription(this.authenticationService.login(this.loginForm.value))
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        credentials => {
          log.debug(`${credentials.data.username} successfully logged in`);
          this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        },
        error => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
