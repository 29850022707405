import { Injectable, OnDestroy } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { AppInjector } from '@app/app.module'
import { UntilDestroy } from '@ngneat/until-destroy'
import { findInvalidControls, SCBaseComponent, SCToastAction, SCToastConfig, SCToastService } from '@symblcrowd/ng-symblcrowd'
import { focusInvalidControl } from './form-helper'

@UntilDestroy()
@Injectable()
export class BaseComponent extends SCBaseComponent implements OnDestroy {
  private toastService: SCToastService = AppInjector.get(SCToastService)

  ngOnDestroy() {
    super.ngOnDestroy()
  }

  formInvalid(actions?: SCToastAction[]) {
    let toastConfig = new SCToastConfig()
    toastConfig.color = 'danger'
    toastConfig.duration = 2000
    return this.openToast('invalid_form', toastConfig, actions)
    //this.toastService.open(this.translateService.instant('invalid_form'), undefined, toastConfig)
  }

  openToast(message: string, config: SCToastConfig, actions?: SCToastAction[]) {
    return this.toastService.open(message, actions, config)
  }

  openSavedToast(message: string = 'saved') {
    let toastConfig = new SCToastConfig()
    toastConfig.color = 'success'
    toastConfig.duration = 2000
    return this.openToast(message, toastConfig)
  }

  checkValidity(form: FormGroup, showToast: boolean = false, additionalCondition: boolean = true) {
    form.markAllAsTouched()
    if (form.valid && additionalCondition) {
      return true
    } else {
      if (showToast) {
        this.formInvalid()
      }
      focusInvalidControl(form)
      console.log(form)
      console.log(findInvalidControls(form, []))
      return false
    }
  }
}
