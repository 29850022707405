import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SCShellHeaderService, SCTableCardComponent, SCTableComponent } from '@symblcrowd/ng-symblcrowd';
import { Subscription } from 'rxjs';

export const TableCardTemplate = `
<sc-card *ngIf='inCard'>
    <sc-card-header *ngIf='title'>
        <sc-card-header-text>
            {{title}}
        </sc-card-header-text>
    </sc-card-header>
    <sc-card-content [content-padded]='false' class='vh-100--shell'>
        <sc-table 
            #scTable
            [urlConfig]='tableUrlConfig' 
            [storageConfig]='tableStorageConfig' 
            [config]='tableConfig' 
            [defaultConfig]='tableDefaultConfig' 
            [data]='tableData' 
            [tableActions]='tableActions' 
            [tableHeaders]='tableHeaders' 
            [tableHeadersDisplayed]='tableHeadersDisplayed' 
            [enableToggleSearchFields]='true'    
            [isLazyLoading]='isLazyLoading'
            (configChanged)='handleConfigChanged($event)'
            (rowClicked)='handleTableRowClicked($event)'
            (actionClicked)='handleTableActionClicked($event)'
            [enableLoadingIndicator]="true"
            [mapFunction]="mapFunction">  
        </sc-table>
    </sc-card-content>
</sc-card>
<sc-table 
    #scTableWithout
    *ngIf='!inCard'
    [urlConfig]='tableUrlConfig' 
    [storageConfig]='tableStorageConfig' 
    [config]='tableConfig' 
    [defaultConfig]='tableDefaultConfig' 
    [data]='tableData' 
    [tableActions]='tableActions' 
    [tableHeaders]='tableHeaders' 
    [tableHeadersDisplayed]='tableHeadersDisplayed' 
    [enableToggleSearchFields]='true'  
    [isLazyLoading]='isLazyLoading'  
    (configChanged)='handleConfigChanged($event)' 
    (rowClicked)='handleTableRowClicked($event)'
    (actionClicked)='handleTableActionClicked($event)'
    [enableLoadingIndicator]="true"
    [mapFunction]="mapFunction">  
</sc-table>`;
@UntilDestroy()
@Component({
  selector: 'table-card-component',
  template: ''
})
export class TableCardComponent extends SCTableCardComponent implements OnDestroy {
  @ViewChild('scTableWithout') scTableComponentWithout: SCTableComponent;
  @ViewChild('scTable') scTableComponentWith: SCTableComponent;

  $headerActionClicked: Subscription

  get scTableComponent() {
    return this.scTableComponentWith || this.scTableComponentWithout;
  }
  constructor(public scShellHeaderService: SCShellHeaderService) {
    super(scShellHeaderService);

    this.$headerActionClicked = this.addHeaderSubscription(this.scShellHeaderService.actionClicked).pipe(untilDestroyed(this)).subscribe(resp =>  
      this.handleActionClicked(resp)
    );
  }
  ngOnDestroy() {
    /*
    * Quick-Fix until we find a better option
    */
    this.$headerActionClicked.unsubscribe()
    super.ngOnDestroy();
  }

  reloadTableData() {
    if (this.scTableComponent) {
      this.scTableComponent.getUrlTableData();
    }
  }

  onRetrieve(route: ActivatedRouteSnapshot) {
    console.log('retrieved');
    if (this.tableUrlConfig && this.tableUrlConfig.tableDataUrl && !this.scTableComponent.isLoadingResults) {
      this.scTableComponent.getUrlTableData();
    }
  }
}