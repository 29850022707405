import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellRouter } from '@app/shell/shell-router';
//import { SCShellRouter } from '@symblcrowd/ng-symblcrowdlib/sc-shell'

const routes: Routes = [
  { path: '', redirectTo: '/companies', pathMatch: 'full' },
  //{ path: ':clientId', redirectTo: '/:clientId/dashboard', pathMatch: 'full' },
  ShellRouter.childRoutes([
    // {
    //   path: 'dashboard',
    //   data: {
    //     breadcrumb: {
    //       label: 'Dashboard',
    //       parsedLabel: 'Dashboard',
    //       url: '/:clientId/dashboard',
    //       parsedUrl: '',
    //       show: true,
    //       key: 'mainMenuItem'
    //     }
    //   },
    //   loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
    // },
    {
      path: 'companies',
      loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule)
    },
    {
      path: 'users',
      loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
    }
  ]),

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/companies', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
