import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SCData } from '@symblcrowd/ng-symblcrowd'
import { combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { BaseService } from './helperclasses/baseservice'
import { HttpService } from './http/http.service'

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class TranslationsService extends BaseService {
  constructor(private http: HttpService) {
    super()
    this.isGlobal = true
  }

  getLanguageFiles() {
    let observables = []
    for (let languageCode of environment.supportedLanguages) {
      observables.push(this.getLanguageFile(languageCode))
    }
    return combineLatest(observables).pipe(
      filter(resp => {
        for (let tmp of resp) {
          if (tmp == undefined) {
            return false
          }
        }
        return true
      }),
      map(resp => {
        return resp.map((resp, index) => {
          return { languageCode: environment.supportedLanguages[index], translations: resp.data }
        })
      })
    )
  }

  getLanguageFile(languageCode: string) {
    return <Observable<SCData<{ [index: string]: string }>>>this.http.get(`/translations/${languageCode}/file`)
  }
}
