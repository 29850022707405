import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { SCLocalModule } from './sc/sc.module';
import { SCSidenavModule } from '@symblcrowd/ng-symblcrowd';
import { SanitizeHtml } from '@app/shared/pipes/sanitize-html.pipe';
import { ImgSrcAsBlobPipe } from './pipes/img-src-as-blob';

@NgModule({
  imports: [FlexLayoutModule, MaterialModule, CommonModule, SCLocalModule],
  declarations: [SanitizeHtml, ImgSrcAsBlobPipe],
  exports: [SCLocalModule, SCSidenavModule, SanitizeHtml, ImgSrcAsBlobPipe],
  providers: []
})
export class SharedModule {}
