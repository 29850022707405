import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@env/environment'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SCData } from '@symblcrowd/ng-symblcrowd'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { webSocket } from 'rxjs/webSocket'
import { BaseService } from './helperclasses/baseservice'
export interface WebsocketMessage {
  message_type: string
  timestamp: string
  status: number
  message: string
  component: string
  foreign_type: string
  foreign_id: string
  foreign_sub_type: string
  foreign_sub_id: string
  action: string
  data: any
}

export const ForeignTypes = {
  //sw invoices, billings etc. zu billing machen?
  Companies: 'COMPANIES',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class WebsocketService extends BaseService {
  private websocketMessage$ = new BehaviorSubject<WebsocketMessage>(undefined)

  private globalWebsocketSub: Subscription
  private globalWebsocketTicketSub: Subscription

  private gloablWebsocketTimeout: any

  get $websocketMessage() {
    return this.websocketMessage$.asObservable()
  }

  constructor(private http: HttpClient, private router: Router) {
    super()
    this.isGlobal = true
  }

  openGlobalWebsocket(token: string, retryTime = 1000) {
    if (this.globalWebsocketTicketSub) {
      this.globalWebsocketTicketSub.unsubscribe()
    }
    if (this.globalWebsocketSub) {
      this.globalWebsocketSub.unsubscribe()
    }
    if (this.gloablWebsocketTimeout) {
      clearTimeout(this.gloablWebsocketTimeout)
    }
    this.globalWebsocketTicketSub = this.addSubscription(
      this.getGlobalWebsocketTicket(token).pipe(
        map(ticketData => {
          return ticketData.data
        })
      )
    ).subscribe(
      ticket => {
        if (this.globalWebsocketSub) {
          this.globalWebsocketSub.unsubscribe()
        }
        this.globalWebsocketSub = this.addSubscription(
          this.createWebsocket<WebsocketMessage>(`${environment.websocketUrl}${environment.apiVersion}/ws/${ticket}`)
        ).subscribe(
          messageData => {
            //console.log(messageData)
            this.websocketMessage$.next(messageData)
          },
          err => {
            this.gloablWebsocketTimeout = setTimeout(() => this.openGlobalWebsocket(token, 3000), retryTime)
          },
          () => {
            console.warn('COMPLETE')
            this.gloablWebsocketTimeout = setTimeout(() => this.openGlobalWebsocket(token), retryTime)
          }
        )
      },
      err => {
        console.error(err)
        console.error(err.status)
        switch (err.status) {
          case 401:
            console.log(this.router.url)
            //this.router.navigate(['/login'], { queryParams: { redirect: this.router.url }, replaceUrl: true });
            break
          default:
            this.gloablWebsocketTimeout = setTimeout(() => this.openGlobalWebsocket(token, 3000), retryTime)
            break
        }
      }
    )
  }

  closeGlobalWebsocket() {
    if (this.globalWebsocketSub) {
      this.globalWebsocketSub.unsubscribe()
      this.globalWebsocketSub = undefined
    }
    if (this.gloablWebsocketTimeout) {
      clearTimeout(this.gloablWebsocketTimeout)
    }
  }

  getGlobalWebsocketTicket(token: string) {
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', 'Bearer ' + token)
    return <Observable<SCData<string>>>this.http.get(environment.serverUrl + environment.apiVersion + '/ws/ticket', { headers: headers })
  }

  createWebsocket<T = any>(url: string, recconnectDelay: number = 1000) {
    return webSocket<T>(url) //.pipe(retryWhen(errors => errors.pipe(delay(1000))));
  }
}
